import React from "react";
import "../styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function MultipleBannerBodies() {
  const strings = [
    {
      bannerWithPictureOnRight: true,
      pictureURL: "/bannerImages/ConsultingService.png",
      stringStart: `Building a `,
      coloredString: "Brighter and Sustainable ",
      stringEnd: " Future of Your Business",
      subString: "One Stop Solution for all your Business Requirements",
    },
    {
      stringStart: `Licensing & `,
      coloredString: "Recognition",
      stringEnd: "...",
      subString: "",
      isImageBanner: true,
      LeftBlockImageList: [
        "/bannerImages/fssai.png",
        "/bannerImages/BOIS.png",
        "/bannerImages/Setting.png",
        "/bannerImages/approved.png",
      ],
      RightBlockImageList: [
        "/bannerImages/fda.png",
        "/bannerImages/productTesting.png",
        "/bannerImages/apeda.png",
        "/bannerImages/eic.png",
      ],
      CenterBlockImageList: ["/bannerImages/gain.png"],
    },
    {
      isCustomBanner: true,
      titleImageName: "/bannerImages/Nabcb.png",
      stringStart: `Licensing & `,
      coloredString: "Recognition",
      stringEnd: "...",
      subString: "End to end Consulting..",
      listOfSubString: [
        "ISO/IEC 17020:2012 – Inspection",
        "ISO/IEC 17021:2015 -  Management System (CB)",
        "ISO/IEC 17065:2012 -  Product / Process",
        "ISO/IEC 17024:2012 -  Certification of Persons",
        "ISO/IEC 17024:2012 -  Certification of Persons",
      ],
      isImageBanner: true,
      LeftBlockImageList: [
        "/bannerImages/fssai.png",
        "/bannerImages/BOIS.png",
        "/bannerImages/Setting.png",
        "/bannerImages/approved.png",
      ],
      RightBlockImageList: [
        "/bannerImages/fda.png",
        "/bannerImages/productTesting.png",
        "/bannerImages/apeda.png",
        "/bannerImages/eic.png",
      ],
      CenterBlockImageList: ["/bannerImages/gain.png"],
    },
    {
      stringStart: `Food and `,
      coloredString: "Feed",
      stringEnd: " Safety",
      subString: "ISO & GFSI Approved Schemes",
      subHeading: "[ ISO 22000, FSSC 22000, BRCGS, GMP, HACCP ]",
      listOfImages: [
        "/bannerImages/foodSafetyManagement.png",
        "/bannerImages/foodSafetyManagement2.png",
        "/bannerImages/foodSafetyManagement3.png",
        "/bannerImages/foodSafetyManagement4.png",
        "/bannerImages/foodSafetyManagement5.png",
      ],
    },
    {
      stringStart: `SeaFood `,
      coloredString: "Sustainability",
      subString: "FOS C-O-C, MSC C-O-C",
      listOfImages: [
        "/bannerImages/sustainibility/image1.png",
        "/bannerImages/sustainibility/image2.png",
      ],
    },
    {
      stringStart: `Sustainability & `,
      coloredString: `CSR..`,
      subString: "FSC C-O-C, ESG, GRI",
      listOfImages: [
        "/bannerImages/csr/image1.png",
        "/bannerImages/csr/image2.png",
        "/bannerImages/csr/image3.png",
        "/bannerImages/csr/image4.png",
        "/bannerImages/csr/image5.png",
      ],
    },
    {
      stringStart: `Quality, `,
      coloredString: "Environmental",
      stringEnd: ", H&S",
      listOfImages: [
        "/bannerImages/environmentalist/image1.png",
        "/bannerImages/environmentalist/image2.png",
        "/bannerImages/environmentalist/image3.png",
        "/bannerImages/environmentalist/image4.png",
        "/bannerImages/environmentalist/image5.png",
      ],
    },
    {
      stringStart: `Product  `,
      coloredString: `Certification..`,
      subString: "CE, BIS, ORGANIC, NON GMO, GLUTEN FREE, VEGAN",
      listOfImages: [
        "/bannerImages/Certification/image1.png",
        "/bannerImages/Certification/image2.png",
        "/bannerImages/Certification/image3.png",
        "/bannerImages/Certification/image4.png",
        "/bannerImages/Certification/image5.png",
        "/bannerImages/Certification/image6.png",
      ],
    },
    {
      stringStart: `Regulatory `,
      coloredString: ` Compliance..`,
      subString: "FSSAI – Third Party Audits",
      listOfImages: [
        "/bannerImages/compliance/image1.png",
        "/bannerImages/compliance/image2.png",
        "/bannerImages/compliance/image3.png",
        "/bannerImages/compliance/image4.png",
        "/bannerImages/compliance/image5.png",
      ],
    },
    {
      stringStart: `MSME  `,
      coloredString: `Sustainability..`,
      subString: "ZeD – Bronze / Silver / Gold",
      listOfImages: ["/bannerImages/sustain/image.png"],
    },
    {
      stringStart: `Capacity  `,
      coloredString: ` Building...`,
      subString:
        "Internal Auditor, Lead Auditor, FoSTaC, Sensory Evaluation, Improvement Tools",
      listOfImages: [
        "/bannerImages/building/image1.png",
        "/bannerImages/building/image2.png",
        "/bannerImages/building/image3.png",
        "/bannerImages/building/image4.png",
      ],
    },
    {
      stringStart: `Food Labeling `,
      coloredString: `Compliance...`,
      subString:
        "Allergen Labelling, Nutritional Facts, FSSAI, Legal Metrology, Label & Display Regulation",
      listOfImages: ["/bannerImages/labeling/image.png"],
    },
    {
      isCustomBanner: true,
      titleImageName: "/bannerImages/mind.png",
      stringStart: `Intellectual `,
      coloredString: "Talks",
      stringEnd: "...",
      listOfSubString: [
        "Changing Face of the Food Industry in India",
        "Future of Fortification in India and across world",
        "Future of Health Supplements Industry",
        "Milk and Milk Product Industry",
        "Millet base Product Industry",
        "Product Improvements",
        "Food Industry step DAIRY/BAKERY/BEVERAGE/KITECHEN/QSR",
        "Business linking Domestic  / Export",
        "Business Feasibility Study",
      ],
      isImageBanner: true,
    },
    {
      bannerWithPictureOnRight: true,
      pictureURL: "/bannerImages/rightImages/image1.png",
      stringStart: `Business `,
      coloredString: "Migration",
      stringEnd: "..",
    },
    {
      bannerWithPictureOnRight: true,
      pictureURL: "/bannerImages/rightImages/image2.png",
      stringStart: `Franchise `,
      coloredString: "Development",
      stringEnd: "..",
    },
    {
      bannerWithPictureOnRight: true,
      pictureURL: "/bannerImages/rightImages/image3.png",
      stringStart: `Beyond Border `,
      coloredString: "Expansion",
      stringEnd: "..",
    },
    {
      bannerWithPictureOnRight: true,
      pictureURL: "/bannerImages/rightImages/image4.png",
      stringStart: `Government `,
      coloredString: "Business",
      stringEnd: "..",
    },
  ];

  const pictureOnRightBanner = (item: any) => {
    return (
      <div className="picture-on-right-banner" style={{ display: "flex" }}>
        <div style={{ flex: 1 }} className="transition-class-with-fade">
          <p className="body-text-bolder">
            {item?.stringStart}
            <span className="body-text-bolder added-text-color">
              {item?.coloredString}
            </span>
            {item?.stringEnd}
          </p>
          <p className="body-text-bold">{item?.subString}</p>
        </div>
        <div style={{ flex: 1 }}>
          <img
            height="100%"
            width="100%"
            //style={{ padding: "1rem" }}
            //className="logo-image"
            src={item?.pictureURL}
            alt="logo"
          ></img>
        </div>
      </div>
    );
  };

  const customBannerWithList = (
    titleImage: string = "",
    startString: string = "",
    coloredString: string = "",
    stringEnd: string = "",
    subString: string = "",
    listOfSubString: string[] = []
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="image-div-wrapper">
          <div style={{ flex: 1 }}>
            {" "}
            <img
              height="150px"
              width="100px"
              style={{ padding: "5px" }}
              //style={{ padding: "1rem" }}
              //className="logo-image"
              src={titleImage}
              alt="logo"
            ></img>
          </div>
          <div style={{ flex: 5 }}>
            <p className="body-text-bolder">
              {startString}
              <span className="body-text-bolder added-text-color">
                {coloredString}
              </span>
              {stringEnd}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "white",
            fontSize: "1.5rem",
            width: "100vw",
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              textAlign: "start",
            }}
          >
            {listOfSubString.map((item: any, index: number) => {
              return (
                <li style={{ textDecoration: "none" }}>
                  {index + 1}
                  {" ) "}
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <p className="body-text-bold">{subString}</p>
      </div>
    );
  };

  const returnImageBannerDiv = (
    showImage: boolean = false,
    leftImageList: any,
    rightImageList: any,
    centerImageList: any
  ) => {
    return (
      <div className="image-div-wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: "1 1 auto",
            width: "fit-content",
          }}
        >
          <div>
            {leftImageList?.map((item: any, index: number) => {
              if (index < 2) {
                return (
                  <img
                    height="100px"
                    width="100px"
                    style={{ padding: "5px" }}
                    //style={{ padding: "1rem" }}
                    //className="logo-image"
                    src={item}
                    alt="logo"
                  ></img>
                );
              }
              return <></>;
            })}
          </div>
          <div>
            {leftImageList?.map((item: any, index: number) => {
              if (index >= 2) {
                return (
                  <img
                    height="100px"
                    width="100px"
                    style={{ padding: "5px" }}
                    //style={{ padding: "1rem" }}
                    //className="logo-image"
                    src={item}
                    alt="logo"
                  ></img>
                );
              }
              return <></>;
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: "1 1 auto",
            justifyContent: "center",
            width: "fit-content",
          }}
        >
          {centerImageList?.map((item: any) => {
            return (
              <img
                height="100px"
                width="100px"
                //className="logo-image"
                src={item}
                alt="logo"
              ></img>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: "1 1 auto",
            width: "fit-content",
          }}
        >
          <div>
            {rightImageList?.map((item: any, index: number) => {
              if (index < 2) {
                return (
                  <img
                    height="100px"
                    width="100px"
                    style={{ padding: "5px" }}
                    //className="logo-image"
                    src={item}
                    alt="logo"
                  ></img>
                );
              }
              return <></>;
            })}
          </div>
          <div>
            {rightImageList?.map((item: any, index: number) => {
              if (index >= 2) {
                return (
                  <img
                    height="100px"
                    width="100px"
                    style={{ padding: "5px" }}
                    //className="logo-image"
                    src={item}
                    alt="logo"
                  ></img>
                );
              }
              return <></>;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="body-wrapper">
      <Carousel
        autoPlay
        infiniteLoop
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        transitionTime={0}
        interval={4000}
      >
        {strings.map((item) => {
          if (item?.isCustomBanner) {
            return customBannerWithList(
              item?.titleImageName,
              item?.stringStart,
              item?.coloredString,
              item?.stringEnd,
              item?.subString,
              item?.listOfSubString
            );
          } else if (item?.bannerWithPictureOnRight) {
            return pictureOnRightBanner(item);
          } else {
            return (
              <div className="transition-class-with-fade">
                <p
                  style={{ flex: 1, height: "15%" }}
                  className="body-text-bolder"
                >
                  {item?.stringStart}
                  <span className="body-text-bolder added-text-color">
                    {item?.coloredString}
                  </span>
                  {item?.stringEnd}
                </p>
                <p style={{ flex: 1, height: "5%" }} className="body-text-bold">
                  {item?.subString}
                </p>
                {!!item?.subHeading && (
                  <p className="body-text-bold">{item?.subHeading}</p>
                )}
                {!!item?.listOfImages?.length && (
                  <div
                    className="list-of-images"
                    style={{
                      display: "flex",
                      flex: 2,
                      height: "80%",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {item?.listOfImages?.map((innerItem: any) => {
                      return (
                        <div>
                          <img
                            height="100px"
                            width="100px"
                            //style={{ padding: "1rem" }}
                            //className="logo-image"
                            src={innerItem}
                            alt="logo"
                          ></img>
                        </div>
                      );
                    })}
                  </div>
                )}
                {item?.isImageBanner &&
                  returnImageBannerDiv(
                    item?.isImageBanner,
                    item?.LeftBlockImageList,
                    item?.RightBlockImageList,
                    item?.CenterBlockImageList
                  )}
              </div>
            );
          }
        })}
      </Carousel>
    </div>
  );
}

export default MultipleBannerBodies;
