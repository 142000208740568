import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage, CompanyPage, ConsultancyPage } from "./pages";
import Header from "./header/Header";
// import ServicePage from "./pages/services-page/ServicePage";
// import ProductPage from "./pages/product-page/ProductPage";
import SideBar from "./sideBar/SideBar";
import { useState } from "react";
import useScreenSize from "./hooks/useScreenSize";
import CommingSoonPage from "./pages/CommingSoonPage/CommingSoonPage";

import ContactUsPage from "./pages/contactUsPage/ContactUsPage";

export default function AppComponent() {
  const [openSideBar, setSideBarState] = useState(false);
  const [isTabletOrMobile] = useScreenSize();
  return (
    <BrowserRouter>
      <div className="App">
        <Header setSideBarState={setSideBarState} />
        {isTabletOrMobile && (
          <SideBar
            setSideBarState={setSideBarState}
            openSideBar={openSideBar}
          />
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route index element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="services" element={<CommingSoonPage />} />
          <Route path="contact_us" element={<ContactUsPage />} />
          <Route path="download" element={<CommingSoonPage />} />
          <Route path="blog" element={<CommingSoonPage />} />
          <Route path="consultancy" element={<ConsultancyPage />} />
          <Route path="company" element={<CompanyPage />} />
          <Route path="e-shop" element={<HomePage />} />
          <Route path="products" element={<CommingSoonPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
