import React, { useState } from "react";
import { FaHome } from "react-icons/fa";
import { GrTask } from "react-icons/gr";
import { IoMdDownload } from "react-icons/io";
import { CiCircleInfo } from "react-icons/ci";
import { IoMdContact } from "react-icons/io";
import { FaBloggerB } from "react-icons/fa6";
import "./styles.css";
import { Link } from "react-router-dom";

export default function TopNavBar() {
  const navList = [
    {
      label: "Home",
      route: "home",
      icon: <FaHome style={{ marginRight: "5px" }} />,
    },
    {
      label: "About Us",
      route: "company",
      icon: <CiCircleInfo style={{ marginRight: "5px" }} />,
    },
    {
      label: "Services",
      route: "services",
      icon: <GrTask style={{ marginRight: "5px" }} />,
    },
    {
      label: "Download",
      route: "download",
      icon: <IoMdDownload style={{ marginRight: "5px" }} />,
    },
    {
      label: "Contact Us",
      route: "contact_us",
      icon: <IoMdContact style={{ marginRight: "5px" }} />,
    },
    {
      label: "Blog",
      route: "blog",
      icon: <FaBloggerB style={{ marginRight: "5px" }} />,
    },
  ];
  const [currentNavigator, setCurrentNavigator] = useState("HOME");
  return (
    <div className="wrapperTopNav">
      <nav className="navbarWrapper">
        {navList.map((item, index) => {
          return (
            <div
              onClick={() => setCurrentNavigator(item.label)}
              key={`${item.label + index}`}
              className={`singleNavBarElement ${
                item.label.toLowerCase() === currentNavigator.toLowerCase() &&
                "active-tab"
              }`}
            >
              {" "}
              <Link
                className="singleNavBarElementIconAndLabel"
                to={`/${item.route.toLowerCase()}`}
              >
                {item.icon}
                {item.label}
              </Link>
            </div>
          );
        })}
      </nav>
    </div>
  );
}
