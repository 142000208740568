import React from "react";
import "../styles.css";
import { FiSmartphone } from "react-icons/fi";

function RightHeader() {
  return (
    <div className="logo-text-wrapper-div">
      <div className="logo-text-wrapper-div">
        <img src="/SocialMediaLogo.png" alt="logo" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FiSmartphone style={{ padding: "0.5rem" }} />
          ContactUs : +91 9220325974
        </div>
      </div>
    </div>
  );
}

export default RightHeader;
