import React from "react";

function CommingSoonPage() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        height="200px"
        width="200px"
        style={{ padding: "5px" }}
        //style={{ padding: "1rem" }}
        //className="logo-image"
        alt="logo"
        src="/comminsoon.webp"
      ></img>
    </div>
  );
}

export default CommingSoonPage;
