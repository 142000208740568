import React from "react";
import "../styles.css";
import useScreenSize from "../../hooks/useScreenSize";

function LeftPartComponent(props: any) {
  const [isTabletOrMobile] = useScreenSize();
  const { firstHeading, firstSubHeading, secondHeading, secondSubText } = props;
  return (
    <div>
      <div className="second-body-text">
        {" "}
        {!!firstHeading && !isTabletOrMobile && (
          <div className="text-wrapper">
            <p className="background-color blue">
              {firstHeading}
              <br></br>
              {!!firstHeading && (
                <span className="subtext">{firstSubHeading}</span>
              )}
            </p>
          </div>
        )}
        {!!secondHeading && (
          <div className="text-wrapper">
            <p className="background-color orange">
              {secondHeading}
              <br></br>
              {!!secondSubText && (
                <span className="subtext">{secondSubText}</span>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LeftPartComponent;
