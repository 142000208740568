import React from "react";
import "./HomePage.css";
import Footer from "../../footer";
//import SecondBody from "../../pageBody/homeBody/Home_SecondBody";
//import FifthBody from "../../pageBody/homeBody/Home_FifthBody";
//import SixthBody from "../../pageBody/homeBody/Home_SixthBody";
//import SevenBody from "../../pageBody/homeBody/Home_SevenBody";
import MultipleBannerBodies from "../../pageBody/homeBody/MultipleBannerBodies";
//import HomeSecurityBody from "../../pageBody/homeBody/HomeSecurityBody";

function HomePage() {
  return (
    <div className="App-main">
      <MultipleBannerBodies />
      {/* <SecondBody />
      <FifthBody />
      <SixthBody />
      <SevenBody />
      <HomeSecurityBody /> */}
      <Footer />
    </div>
  );
}

export default HomePage;
