import React from "react";
import HomeFooter from "./homeFooter/HomeFooter";

function Footer() {
  return (
    <div>
      {" "}
      <HomeFooter />
    </div>
  );
}

export default Footer;
