import React from "react";
import "./styles.css";
import LeftHeader from "./left-header/LeftHeader";
import RightHeader from "./right-header/RightHeader";
import TopNavBar from "../top-navbar/TopNavBar";
import useScreenSize from "../hooks/useScreenSize";
import TopRedirectionBar from "./TopRedirectionBar";

export default function Header(props: any) {
  const [isTabletOrMobile] = useScreenSize();
  return (
    <div className="header-wrapper">
      {isTabletOrMobile && (
        <TopRedirectionBar setSideBarState={props?.setSideBarState} />
      )}
      <LeftHeader />
      {!isTabletOrMobile && <TopNavBar />}
      {!isTabletOrMobile && <RightHeader />}
    </div>
  );
}
