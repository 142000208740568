import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";

function TopRedirectionBar(props: any) {
  return (
    <div
      style={{ display: "flex", backgroundColor: "#104862", padding: "0.5rem" }}
    >
      <div style={{ flex: 1 }}>
        <GiHamburgerMenu
          color="white"
          onClick={() => props?.setSideBarState((prev: any) => !prev)}
        />
      </div>
      <div style={{ marginLeft: "20px", flex: 2 }}>
        <img
          height="20px"
          width="80px"
          //className="logo-image"
          src="/tmclogo.jpg"
          alt="logo"
        />
      </div>
    </div>
  );
}

export default TopRedirectionBar;
