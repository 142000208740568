import React from "react";
import { FaHome } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrTask } from "react-icons/gr";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { IoMdDownload } from "react-icons/io";
import { CiCircleInfo } from "react-icons/ci";
import { IoMdContact } from "react-icons/io";
import { FaBloggerB } from "react-icons/fa6";
import { Link } from "react-router-dom";

function SideBar(props: any) {
  return (
    <div>
      {props?.openSideBar && (
        <Sidebar
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              zIndex: 400,
              width: "100vw",
              background: "white",
              position: "fixed",
              top: 0,
            },
          }}
        >
          <GiHamburgerMenu
            style={{ padding: "0.4rem", height: "1rem", width: "1rem" }}
            color="black"
            onClick={() => props?.setSideBarState((prev: any) => !prev)}
          />
          <Menu
            style={{
              height: "100vh",
              justifyContent: "space-evenly",
            }}
          >
            <img
              height="100px"
              width="280px"
              //className="logo-image"
              src="/tmclogo.jpg"
              alt="logo"
            />
            <MenuItem
              onClick={() => props?.setSideBarState((prev: any) => !prev)}
            >
              <Link className="singleNavBarElementIconAndLabel" to={`/home`}>
                <FaHome style={{ marginRight: "5px" }} /> Home
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => props?.setSideBarState((prev: any) => !prev)}
            >
              <Link
                className="singleNavBarElementIconAndLabel"
                to={`/company `}
              >
                <CiCircleInfo style={{ marginRight: "5px" }} /> About Us
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => props?.setSideBarState((prev: any) => !prev)}
            >
              <Link
                className="singleNavBarElementIconAndLabel"
                to={`/services `}
              >
                <GrTask style={{ marginRight: "5px" }} /> Services
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => props?.setSideBarState((prev: any) => !prev)}
            >
              <Link
                className="singleNavBarElementIconAndLabel"
                to={`/download `}
              >
                <IoMdDownload style={{ marginRight: "5px" }} /> Download
              </Link>
            </MenuItem>

            <MenuItem
              onClick={() => props?.setSideBarState((prev: any) => !prev)}
            >
              <Link
                className="singleNavBarElementIconAndLabel"
                to={`/contact_us `}
              >
                <IoMdContact style={{ marginRight: "5px" }} /> Contact Us
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => props?.setSideBarState((prev: any) => !prev)}
            >
              <Link className="singleNavBarElementIconAndLabel" to={`/blog `}>
                <FaBloggerB style={{ marginRight: "5px" }} /> Blog
              </Link>
            </MenuItem>
          </Menu>
        </Sidebar>
      )}
    </div>
  );
}

export default SideBar;
