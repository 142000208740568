import React from "react";

function LeftFooter() {
  return (
    <div>
      <p className="footer-left-text">
        <span className="footer-left-text-bold">Contact Us:</span>
        <br></br>
        <span>
          Get in TOUCH<br></br>
          hello@tmc-consultancy.in<br></br>
          Sustainability.tmc@gmail.com
        </span>
        <br></br>
        <span>
          Ph: +91 9220325974<br></br> Email: infotaanvi@gmail.com
        </span>
        <br></br>
        <span>Indian Company</span>
      </p>
    </div>
  );
}

export default LeftFooter;
