import React from "react";
import LeftFooter from "./LeftFooter";
import RightFooter from "./RightFooter";
import "../styles.css";
import useScreenSize from "../../hooks/useScreenSize";

function HomeFooter() {
  const [isTabletOrMobile] = useScreenSize();
  return (
    <div className="row-footer-wrapper">
      <LeftFooter />
      {!isTabletOrMobile && <RightFooter />}
    </div>
  );
}

export default HomeFooter;
